<template>
  <div
    class="cart-shipping"
    :class="{ 'cart-shipping--qualify': !getHasShortfall }"
  >
    <template v-if="getTargetValueCents > 0">
      <div class="cart-shipping__lead-copy">
        <RichText
          :content="getLeadText"
          :baseClass="'text-commerce-intro'"
          class="bp-cart-drawer__shipping-text text--center text--clr-black"
        />
      </div>
      <div class="cart-shipping__progress-wrapper">
        <CartUpsellProgress
          :max="getTargetValueCents"
          :value="getCartValueCents"
          :iconSrc="getSliderIconSrc"
          :isProduct="!getHasCombiPromo && getHasValuePromo"
        />
      </div>
    </template>
    <div class="cart-shipping__sub-copy">
      <RichText
        :content="getSubText"
        :baseClass="'text-body-small'"
        class="bp-cart-drawer__shipping-text text--center text--clr-black"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import RichText from "@/components/RichText"
import CartUpsellProgress from "./CartUpsellProgress.vue"

import { promoTypes, quantityStatusTypes } from "@/store/constants"

export default {
  props: {},

  components: {
    RichText,
    CartUpsellProgress
  },

  created() {
    this.uiTextContent_manifest = [
      "cart_drawer_upsell_text_lead_shortfall_combi",
      "cart_drawer_upsell_text_lead_shortfall_combi_mystery",
      "cart_drawer_upsell_text_lead_shortfall_gwp",
      "cart_drawer_upsell_text_lead_shortfall_gwp_mystery",
      "cart_drawer_upsell_text_lead_shortfall_shipping",
      "cart_drawer_upsell_text_lead_qualified_combi",
      "cart_drawer_upsell_text_lead_qualified_combi_mystery",
      "cart_drawer_upsell_text_lead_qualified_gwp",
      "cart_drawer_upsell_text_lead_qualified_gwp_mystery",
      "cart_drawer_upsell_text_lead_qualified_shipping",
      "cart_drawer_upsell_text_sub_combi",
      "cart_drawer_upsell_text_sub_gwp",
      "cart_drawer_upsell_text_sub_shipping"
    ]
  },

  computed: {
    ...mapState("cart", ["gwpPromo"]),
    ...mapGetters("content", ["GET_PRODUCT_ENTRY", "GET_SITE_CONFIG"]),
    ...mapGetters("locale", ["formatPrice"]),
    ...mapGetters("cart", [
      "getCartTotalCents",
      "getCartAdjustedTotalCents",
      "getPromoApplicableTotalCents",
      "getIsPromoTriggered"
    ]),

    getCartValueCents() {
      // Both GWP and shipping thresholds are exclusive of any gift cards in the cart
      return this.getPromoApplicableTotalCents
    },

    getHasShortfall() {
      return this.getCartValueCents < this.getTargetValueCents
    },

    getHasCombiPromo() {
      // If the GWP threshold is the same as the Free Shipping threshold, FE shows the 'combi' upsell
      return (
        this.getHasValuePromo &&
        this.gwpPromo.threshold === this.getShippingThreshold
      )
    },

    getHasValuePromo() {
      // If there is a valid GWP promotion
      return !!(
        this.gwpPromo &&
        this.gwpPromo.promotionType == promoTypes.VALUE &&
        this.gwpPromo.promotionalProductsCollection &&
        this.gwpPromo.promotionalProductsCollection.items.length > 0 &&
        this.gwpPromo.promotionalProductsCollection.items[0].quantityStatus !==
          quantityStatusTypes.OUT_OF_STOCK &&
        this.gwpPromo.promotionalProductsCollection.items[0].waitlist !== true
      )
    },

    getLeadText() {
      if (this.getHasShortfall) {
        const _shortfallCents =
          this.getTargetValueCents - this.getCartValueCents
        return this.UI_TEXT_CONTENT(
          this.getHasCombiPromo
            ? "cart_drawer_upsell_text_lead_shortfall_combi" +
                (this.gwpPromo.mystery ? "_mystery" : "")
            : this.getHasValuePromo
            ? "cart_drawer_upsell_text_lead_shortfall_gwp" +
              (this.gwpPromo.mystery ? "_mystery" : "")
            : "cart_drawer_upsell_text_lead_shortfall_shipping",
          [
            {
              tag: "SHORTFALL",
              value: this.formatPrice({ cents: _shortfallCents })
            },
            {
              tag: "PRODUCT_TITLE",
              value: this.getPromoProduct && this.getPromoProduct.displayTitle
            }
          ]
        )
      } else {
        return this.UI_TEXT_CONTENT(
          this.getHasCombiPromo
            ? "cart_drawer_upsell_text_lead_qualified_combi" +
                (this.gwpPromo.mystery ? "_mystery" : "")
            : this.getHasValuePromo
            ? "cart_drawer_upsell_text_lead_qualified_gwp" +
              (this.gwpPromo.mystery ? "_mystery" : "")
            : "cart_drawer_upsell_text_lead_qualified_shipping",
          [
            {
              tag: "PRODUCT_TITLE",
              value: this.getPromoProduct && this.getPromoProduct.displayTitle
            }
          ]
        )
      }
    },

    getPromoProduct() {
      const promoProduct =
        (this.gwpPromo &&
          this.gwpPromo.promotionalProductsCollection &&
          this.gwpPromo.promotionalProductsCollection.items[0]) ||
        null

      return (
        promoProduct &&
        this.GET_PRODUCT_ENTRY(promoProduct.sys.id, promoProduct.__typename)
      )
    },

    getSubText() {
      if (this.getHasCombiPromo) {
        return this.UI_TEXT_CONTENT("cart_drawer_upsell_text_sub_combi")
      } else if (this.getHasValuePromo) {
        return this.UI_TEXT_CONTENT("cart_drawer_upsell_text_sub_gwp")
      } else if (this.getShippingThreshold) {
        return this.UI_TEXT_CONTENT("cart_drawer_upsell_text_sub_shipping")
      } else {
        return null
      }
    },

    getShippingThreshold() {
      return this.GET_SITE_CONFIG.freeShippingThreshold
    },

    getSliderIconSrc() {
      if (this.getHasCombiPromo) {
        return `${process.env.VUE_APP_CLOUDINARY_BASE_PATH}/image/upload/v1646134752/master_platform/icons/shipping.png`
      } else if (this.getHasValuePromo) {
        // return the src of the product image
        return (
          this.getPromoProduct &&
          this.getPromoProduct.images &&
          this.getPromoProduct.images[0].original_secure_url
        )
      } else {
        return `${process.env.VUE_APP_CLOUDINARY_BASE_PATH}/image/upload/v1646134752/master_platform/icons/shipping.png`
      }
    },

    getTargetValueCents() {
      if (this.getHasValuePromo) {
        // If there's a GWP promotion, use the target value (can be used for both GWP and combi promos)
        return this.gwpPromo.threshold
      } else if (this.getShippingThreshold) {
        // else use the 'Free Shipping' threshold
        return this.getShippingThreshold
      } else {
        // else bleh
        return 0
      }
    }
  }
}
</script>

<style lang="scss">
.cart-shipping {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 8px 16px 8px;
  background-color: getcolour(bleach_white);
  transition: background-color 0.1s linear;
  filter: drop-shadow(0 10px 5px rgba(getcolour(bleach_black), 0.05));
}
</style>
